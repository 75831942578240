<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">数据</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div>
        <div class="water">
          <div class="title">动销率</div>
          <dv-water-level-pond class="dv-wa-le-po" :config="dongXiaoLv" />
        </div>
        <div class="water">
          <div class="title">备货率</div>
          <dv-water-level-pond class="dv-wa-le-po" :config="beiHuoLv" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
import { mapState } from 'vuex';
export default {
  components: {
    // CenterLeft2Chart
  },
  computed: {
    // 使用对象展开运算符将此对象混入到外部对象中
    ...mapState({
      // 为了能够使用 `this` 获取局部状态，必须使用常规函数
      beiHuoLv(state){
        let allData = state.allData
        return {
          data: [24, (allData.beiHuoLv*100).toFixed(2)],
          shape: 'roundRect',
          formatter: '{value}%',
          waveNum: 3
        }
      },
      dongXiaoLv(state){
        let allData = state.allData
        return {
          colors:['#fb7293', '#fb7293'],
          data: [24, (allData.dongXiaoLv*100).toFixed(2)],
          shape: 'roundRect',
          formatter: '{value}%',
          waveNum: 3
        }
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
.water {
  width: 100%;
  .title{ text-align: center; font-size: 18px; height: 25px; margin-top: 22px;}
  .dv-wa-le-po {
    height: 120px;
  }
}
</style>