<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">库存分类比列</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div class="d-flex jc-center">
          <Echart
            :options="options"
            id="centreLeft1Chart"
            height="470px"
            width="470px"
          ></Echart>
        </div>
        <div style="width: 50%;" >
          <dv-capsule-chart :config="config" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
import Echart from '@/common/echart'
import { mapState } from 'vuex';
export default {
  data(){
    return {
      color: [
        "#37a2da",
        "#32c5e9",
        "#9fe6b8",
        "#ffdb5c",
        "#ff9f7f",
        "#fb7293",
        "#e7bcf3",
        "#8378ea"
      ],
      // config: {
      //   colors: [
      //   "#37a2da",
      //   "#32c5e9",
      //   "#9fe6b8",
      //   "#ffdb5c",
      //   "#ff9f7f",
      //   "#fb7293",
      //   "#e7bcf3",
      //   "#8378ea"
      // ],
      //   data: [
      //     {
      //       name: '南阳',
      //       value: 167
      //     },
      //     {
      //       name: '周口',
      //       value: 67
      //     },
      //     {
      //       name: '漯河',
      //       value: 123
      //     },
      //     {
      //       name: '郑州',
      //       value: 55
      //     },
      //     {
      //       name: '西峡',
      //       value: 98
      //     }
      //   ]
      // }
    }
  },
  components: {
    Echart
  },
  computed: {
    // 使用对象展开运算符将此对象混入到外部对象中
    ...mapState({
      // 为了能够使用 `this` 获取局部状态，必须使用常规函数
      options(state){
        let allData = state.allData
        return {
          color: this.color,
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            show: false,
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            x: "center",
            data: Object.keys(allData.kuCunFenLeiBiLi),
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: '库存分类比列',
              type: 'pie',
              radius: '50%',
              // radius: [10, 120],
              // roseType: "area",
              // center: ["50%", "40%"],
              data: Object.keys(allData.kuCunFenLeiBiLi).map(item=> {return {value: allData.kuCunFenLeiBiLi[item]?.size, name: item}}),
              label: {
                normal: {
                  show: true,
                  formatter: "{d}%"
                  // formatter: "{b}{d}%"
                }
              },
              animationType: "scale",
            }
          ],
        }
      },
      config(state){
        let allData = state.allData
        return {
          data: Object.keys(allData.kuCunFenLeiBiLi).map(item=> {return {value: allData.kuCunFenLeiBiLi[item]?.size, name: item}}),
          colors: this.color,
        }
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
#bottomRight {
  $box-width: 300px;
  $box-height: 520px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>