<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class=" text mx-2">热销商品TOP30</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board v-if="config.rowNum > 0" class="dv-scr-board" :config="config" />
        <div v-else class="dv-empty" style="padding-top: 150px;">
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      
    }
  },
  computed:{
    ...mapState({
      // 为了能够使用 `this` 获取局部状态，必须使用常规函数
      config(state) {
        let allData = state.allData
        return {
          header: ['名称', 'SKUID'],
          data: allData.hotShopSpuSpecPriceList.map(item=>[item.spuName, item.skuId]),
          rowNum: allData.hotShopSpuSpecPriceList.length, //表格行数
          headerHeight: 35,
          headerBGC: '#0f1325', //表头
          oddRowBGC: '#0f1325', //奇数行
          evenRowBGC: '#171c33', //偶数行
          index: true,
          columnWidth: [50],
          align: ['center']
        }
      },
      
    }),
  }
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 300px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 270px;
      height: 340px;
    }
  }
}
</style>
