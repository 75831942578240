<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import moment from 'moment'
import { mapState } from 'vuex';
export default {
  data () {
    return {
      // cdata: {
      //   category: [
      //     "市区",
      //     "万州",
      //     "江北",
      //     "南岸",
      //     "北碚",
      //     "綦南",
      //     "长寿",
      //     "永川",
      //     "璧山",
      //     "江津",
      //     "城口",
      //     "大足",
      //     "垫江",
      //     "丰都",
      //     "奉节",
      //     "合川",
      //     "江津区",
      //     "开州",
      //     "南川",
      //     "彭水",
      //     "黔江",
      //     "石柱",
      //     "铜梁",
      //     "潼南",
      //     "巫山",
      //     "巫溪",
      //     "武隆",
      //     "秀山",
      //     "酉阳",
      //     "云阳",
      //     "忠县",
      //     "川东",
      //     "检修"
      //   ],
      //   lineData: [
      //     18092,
      //     20728,
      //     24045,
      //     28348,
      //     32808,
      //     36097,
      //     39867,
      //     44715,
      //     48444,
      //     50415,
      //     56061,
      //     62677,
      //     59521,
      //     67560,
      //     18092,
      //     20728,
      //     24045,
      //     28348,
      //     32808,
      //     36097,
      //     39867,
      //     44715,
      //     48444,
      //     50415,
      //     36097,
      //     39867,
      //     44715,
      //     48444,
      //     50415,
      //     50061,
      //     32677,
      //     49521,
      //     32808,
      //     32808
      //   ],
      //   barData: [
      //     4600,
      //     5000,
      //     5500,
      //     6500,
      //     7500,
      //     8500,
      //     9900,
      //     12500,
      //     14000,
      //     21500,
      //     23200,
      //     24450,
      //     25250,
      //     33300,
      //     4600,
      //     5000,
      //     5500,
      //     6500,
      //     7500,
      //     8500,
      //     9900,
      //     22500,
      //     14000,
      //     21500,
      //     8500,
      //     9900,
      //     12500,
      //     14000,
      //     21500,
      //     23200,
      //     24450,
      //     25250,
      //     7500,
      //     7500
      //   ],
      //   rateData: []
      // }
    };
  },
  components: {
    Chart,
  },
  computed: {
    // 使用对象展开运算符将此对象混入到外部对象中
    ...mapState({
      // 为了能够使用 `this` 获取局部状态，必须使用常规函数
      cdata(state){
        let allData = state.allData
        return {
          category: Object.keys(allData.jinQiTianXiaoShouShuJu).map(item=> moment(item).format('YYYY-MM-DD')),
          xiaoShouE: Object.keys(allData.jinQiTianXiaoShouShuJu).map((key) => allData.jinQiTianXiaoShouShuJu[key].xiaoShouE),
          tuiDanE: Object.keys(allData.jinQiTianXiaoShouShuJu).map((key) => allData.jinQiTianXiaoShouShuJu[key].tuiDanE),
          dingDanLiang: Object.keys(allData.jinQiTianXiaoShouShuJu).map((key) => allData.jinQiTianXiaoShouShuJu[key].dingDanLiang),
          tuiDanLiang: Object.keys(allData.jinQiTianXiaoShouShuJu).map((key) => allData.jinQiTianXiaoShouShuJu[key].tuiDanLiang),
        }
      },
    }),
  },
  mounted () {
    
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>