<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["管理后台"],
        seriesData: [
          { value: 10, name: "管理后台" },
          // { value: 5, name: "data2" },
          // { value: 15, name: "data3" },
          // { value: 25, name: "data4" },
          // { value: 20, name: "data5" },
          // { value: 35, name: "data6" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>