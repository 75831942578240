<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="320px"
      width="400px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            // "#37a2da",
            // "#32c5e9",
            // "#9fe6b8",
            // "#ffdb5c",
            // "#ff9f7f",
            // "#fb7293",
            // "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: "来源统计",
              type: "pie",
              radius: [10, 120],
              roseType: "area",
              center: ["50%", "40%"],
              data: newData.seriesData,
              label: {
                normal: {
                  show: true,
                  formatter: "{d}%"
                }
              },
              animationType: "scale",
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>