<template>
  <div id="center">
    <div class="up">
      <dv-border-box-8
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b ">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </dv-border-box-8>
    </div>
    <!-- <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class=" text mx-2 mb-1 pl-3">年度负责人组件达标榜</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>今日任务通过率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>今日任务达标率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <div class="title">动销率</div>
          <dv-water-level-pond class="dv-wa-le-po" :config="dongXiaoLv" />
        </div>
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>今日任务通过率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>今日任务达标率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <div class="title">备货率</div>
          <dv-water-level-pond class="dv-wa-le-po" :config="beiHuoLv" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import CenterChart from '@/components/echart/center/centerChartRate'
import { mapState } from 'vuex';
export default {
  data() {
    return {
      ranking: {
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 78
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          },
          {
            name: '新乡2',
            value: 80
          },
          {
            name: '新乡3',
            value: 80
          },
          {
            name: '新乡4',
            value: 80
          },
          {
            name: '新乡5',
            value: 80
          },
          {
            name: '新乡6',
            value: 80
          }
        ],
        carousel: 'single',
        unit: '人'
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 60,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 40,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    // CenterChart
  },
  computed: {
    // 使用对象展开运算符将此对象混入到外部对象中
    ...mapState({
      // 为了能够使用 `this` 获取局部状态，必须使用常规函数
      titleItem(state) {
        let allData = state.allData
        return [
        {
          title: '订单数',
          number: {
            number: [allData.dingDanLiang],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '销售额',
          number: {
            number: [allData.xiaoShouE],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '退单数',
          number: {
            number: [allData.tuiDanShu],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '退单额',
          number: {
            number: [allData.tuiDanE],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '客户总数',
          number: {
            number: [allData.keHuZongShu],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '近7天新增客户数',
          number: {
            number: [allData.jinQiTianXinZengKeHuShu],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '欠款客户数',
          number: {
            number: [allData.qianKuanKeHuZongShu],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '下单客户数',
          number: {
            number: [allData.xiaDanKeHuShu],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
      ]
      },
      beiHuoLv(state){
        let allData = state.allData
        return {
          data: [24, (allData.beiHuoLv*100).toFixed(2)],
          shape: 'roundRect',
          formatter: '{value}%',
          waveNum: 3
        }
      },
      dongXiaoLv(state){
        let allData = state.allData
        return {
          data: [24, (allData.dongXiaoLv*100).toFixed(2)],
          shape: 'roundRect',
          formatter: '{value}%',
          waveNum: 3
        }
      },
    }),
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 15px;
      margin-top: 8px; 
      margin-bottom: 8px;
      width: 48%;
      height: 85px;
      .dv-dig-flop {
        // width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    // .percent {
    //   width: 40%;
    //   display: flex;
    //   flex-wrap: wrap;
    //   .item {
    //     width: 50%;
    //     height: 120px;
    //     span {
    //       margin-top: 8px;
    //       font-size: 14px;
    //       display: flex;
    //       justify-content: center;
    //     }
    //   }
    //   .water {
    //     width: 100%;
    //     .title{ text-align: center; font-size: 18px; height: 25px;}
    //     .dv-wa-le-po {
    //       height: 120px;
    //     }
    //   }
    // }
  }
}
</style>
